import React from 'react'
import logo from '../assets/SupplyKitLogo.svg';


function Footer() {
  return (
    <div className="text-center"  style={{ backgroundColor: '#FFF7ED' }}>
<div className='flex justify-between px-8 items-center'>
<img src={logo} alt="Supply Kit" className='w-32  my-5'/>
<div className=''><a href="https://instagram.com/supply.kit">Follow us on instagram</a> </div>


</div>

    </div>
  )
}

export default Footer