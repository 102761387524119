import React, { useState, useEffect } from 'react'
import { onSnapshot, getDocs } from 'firebase/firestore'
import QuizComplete from './QuizComplete'
import CountdownTimer from './CountdownTimer'

function Questions({ firebaseDoc, userID }) {
  const [items, setItems] = useState([])
  const [selectedOptionIndex, setSelectedOptionIndex] = useState(-1)
  const [score, setScore] = useState(0)
  const [size, setSize] = useState(0)
  const [points, setPoints] = useState('')
  const [questionCount, setQuestionCount] = useState(1)

  useEffect(() => {
    const questions = firebaseDoc.collection('questions')
    const unsubscribe = onSnapshot(questions, (snapshot) => {
      setItems(snapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() })))
    })
    getDocs(questions).then((querySnapshot) => {
      setSize(querySnapshot.size)
    })
    return unsubscribe
  }, [])

  const checkAnswer = (selectedOptionIndex, correctAnswer) => {
    if (selectedOptionIndex === correctAnswer) {
      setScore((prev) => prev + points)
    } else {
      console.log('incorrect')
    }
    setSelectedOptionIndex(selectedOptionIndex)
    setTimeout(() => {
      setQuestionCount((prev) => prev + 1)
      setSelectedOptionIndex(-1)
    }, 800)
  }

  const handleAddPoints = (value) => {
    setPoints(value)
  }

  const getCardClass = (index, correctAnswer) =>
    selectedOptionIndex === index ? (index === correctAnswer ? 'correct' : 'incorrect') : ''

  const displayMoreQuestions = questionCount <= size

  return (
    <div>
      {items.map((item, i) => {
        if (i === questionCount - 1) {
          return (
            <div key={item.id}>
              <div className='bg-rose-500 px-4 py-8'>
              <h1 className="text-2xl grow text-center">{item.question}</h1>
</div>
              <div className="p-5">
                <h4>
                  Question {questionCount} / {size}
                </h4>
                <h4>Points this round: {points}</h4>
                <h4>Your score: {score}</h4>
                {item.answer &&
                  item.answer.length > 1 &&
                  item.answer.map((option, index) => (
                    <div
                      className={`text-xl rounded-md border-2 p-4 my-4 border-slate-200 card ${getCardClass(
                        index,
                        item.correctAnswer
                      )}`}
                      onClick={() => checkAnswer(index, item.correctAnswer)}
                      key={index}
                    >
                      {option}
                    </div>
                  ))}
                <p className="text-sm">
                  {item.id} :::::: Option selected: {selectedOptionIndex} :::::: question{questionCount}
                </p>
              </div>
              <CountdownTimer onAddPoints={handleAddPoints} />
            </div>
          )
        }
        return null;
      })}
      {questionCount > size && <QuizComplete score={score} firebaseDoc={firebaseDoc} userID={userID} />}
    </div>
  )
  
    }

export default Questions
