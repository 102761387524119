import React from 'react'
import { Link } from 'react-router-dom'

function LandingPage() {
  return (
    <div className="text-center py-20">
      <div className='text-3xl py-20'>LandingPage</div>
      <Link to="questions">
        <button className="bg-sky-400 text-white p-10 text-2xl rounded-xl">
          Let's Go!
        </button>
      </Link>
    </div>
  )
}

export default LandingPage
