import React, { useEffect, useState } from 'react'
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import Footer from './components/Footer'
import Header from './components/Header'
import LandingPage from './components/LandingPage'
import Scoreboard from './components/Scoreboard'
import Questions from './components/Questions'
import queryString from 'query-string'
import firebase from './firebase'

function App() {
  const [userIdExists, setUserIdExists] = useState(false) // Add state variable to keep track of whether user ID exists
  const [userIdPath, setUserIdPath] = useState('') // Add state variable to keep track of whether user ID exists
  const queryParams = queryString.parse(window.location.search)
  const userId = queryParams.user || 'default value' // Use `userId` instead of `value`

  // ACCESS FIREBASE
  const db = firebase.firestore()
  const firebaseDoc = db
    .collection('SupplyKit')
    .doc('Events')
    .collection('Profiles')
    .doc(userId)

  useEffect(() => {
    firebaseDoc
      .get()
      .then((doc) => {
        if (doc.exists) {
          setUserIdExists(true) // Set state variable to true if document exists
          setUserIdPath(userId)
          console.log('user exists')
        } else {
          setUserIdExists(false) // Set state variable to false if document does not exist
          console.log('user does not exist')
        }
      })
      .catch((error) => {
        console.log('Error getting document:', error)
      })
  }, []) // Run the effect only once, when the component mounts

  console.log(userId)

  return (
    <Router>
      <div className="bg-slate-800  ">
        <div className="bg-white w-full max-w-xl mx-auto h-screen flex flex-col relative">
          <div className="grow-0">Hi {userIdPath}</div>
          <div className="grow">
            <Routes>
              <Route
                exact
                path="/"
                element={
                  <>
                    {userIdExists ? (
                      <>
                        <Header />
                        <LandingPage firebaseDoc={firebaseDoc} />
                      </>
                    ) : (
                      <p>User ID not found</p>
                    )}
                  </>
                }
              ></Route>

              <Route
                path="/questions"
                element={
                  <>
                    {userIdExists ? (
                      <Questions firebaseDoc={firebaseDoc} userId={userId} />
                    ) : (
                      <p>User ID not found</p>
                    )}
                  </>
                }
              ></Route>

              <Route
                path="/scoreboard"
                element={
                  <>
                    {userIdExists ? (
                      <Scoreboard firebaseDoc={firebaseDoc} />
                    ) : (
                      <p>User ID not found</p>
                    )}
                  </>
                }
              ></Route>
            </Routes>
          </div>
          <div className="sticky bottom-0 w-full ">
            <Footer />
          </div>
        </div>
      </div>
    </Router>
  )
}

export default App
