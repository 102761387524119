import React, { useState } from 'react';
import Scoreboard from './Scoreboard';
import { Navigate } from 'react-router-dom';


function QuizComplete({ score, firebaseDoc, userID }) {
  const [name, setName] = useState(''); // add state to store the user's name

  const [formSubmitted, setFormSubmitted] = useState(false);


  const handleNameChange = (event) => {
    setName(event.target.value);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
  
    if (name.trim() !== '') {
      const scoreboardRef = firebaseDoc.collection('scores');
      scoreboardRef.add({ name, score }); // add a new document with the user's name and score
      setName(''); // clear the input field after submitting
      setFormSubmitted(true); // set formSubmitted to true
    }
  };
  if (formSubmitted) {
    return <Navigate to={`/scoreboard`} />;
  }
    

  return (
    <div className="w-5/6 mx-auto text-center flex flex-col h-full">
      <h1 className="text-3xl pb-10">Nice one, you scored {score} points!</h1>
      <h4 className="text-xl">Let's see how you rank among the guests...</h4>
  
      <form onSubmit={handleSubmit}>
        <label htmlFor="name" className="block text-xl font-bold mt-8">
          Enter your name:
        </label>
        <input
          type="text"
          id="name"
          value={name}
          onChange={handleNameChange}
          className="block border-2 border-gray-400 rounded-lg p-2 mt-2 mx-auto"
        />
        <button
          type="submit"
          className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-lg mt-4"
        >
          Submit
        </button>
      </form>
  
      {formSubmitted && <Scoreboard firebaseDoc={firebaseDoc} />}
    </div>
  );
  
}

export default QuizComplete;
