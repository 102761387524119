import { useState, useEffect } from 'react';

function CountdownTimer({ onAddPoints }) {
  const availablePoints = 100;
  const totalTime = 10000; // Time in milliseconds
  const unitDuration = totalTime / availablePoints;

  const [timeLeft, setTimeLeft] = useState(availablePoints);
  const [points, setPoints] = useState(availablePoints); // set an initial value for points

  useEffect(() => {
    const intervalId = setInterval(() => {
      setTimeLeft((prevTime) => prevTime - 1);
      setPoints((prevPoints) => prevPoints - 1); // update points directly
    }, unitDuration);

    const timeoutId = setTimeout(() => {
      clearInterval(intervalId);
    }, totalTime);

    return () => {
      clearInterval(intervalId);
      clearTimeout(timeoutId);
    };
  }, []);

  const barWidth = `${(timeLeft / availablePoints) * 100}%`;
  const barStyle = {
    width: barWidth,
    transition: `width ${unitDuration}ms linear`
  };

  useEffect(() => {
    onAddPoints(points);
  }, [points, onAddPoints]);

  return (
    <div>
      <div className='bg-slate-200' style={{ height: '20px' }}>
        <div className='bg-rose-500' style={{ height: '100%', ...barStyle }}></div>
      </div>
    </div>
  );
}

export default CountdownTimer;
