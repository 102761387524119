function Header() {
  return (
    <div className="w-5/6 mx-auto text-center">
      
      <h1 className="text-3xl">This is the header section</h1>
      
      </div>
  )
}

export default Header