import React, { useState, useEffect } from 'react';

function Scoreboard({ firebaseDoc }) {

    const [scoreboardData, setScoreboardData] = useState([]);


    useEffect(() => {
        if (!firebaseDoc) return;
    
        const scoreboardRef = firebaseDoc.collection('scores');
        scoreboardRef.get().then((querySnapshot) => {
          const data = querySnapshot.docs.map((doc) => doc.data());
          setScoreboardData(data);
        });
      }, [firebaseDoc]);
    
      const filteredData = scoreboardData.filter(({ score }) => score && score > 0);
      const sortedData = filteredData.sort((a, b) => b.score - a.score);
    


  return (
    <div className="container border-4 border-slate-200 rounded-xl max-w-md mx-auto">
    <div className="flex flex-col text-left p-5">
      {sortedData.map(({ name, score }) => (
        <div className="flex justify-between text-2xl py-2">
          <div className="w-4/6">{name}</div>
          <div className="w-2/6 text-right">{score}</div>
        </div>
      ))}
    </div>
  </div>
  )
}

export default Scoreboard